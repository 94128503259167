// extracted by mini-css-extract-plugin
export var root = "KnowledgeBaseCategory-module--root--08TLM";
export var wrap = "KnowledgeBaseCategory-module--wrap--qmH6S";
export var ico = "KnowledgeBaseCategory-module--ico---D8JE";
export var categoryWrap = "KnowledgeBaseCategory-module--categoryWrap--3i5dX";
export var categoryTitle = "KnowledgeBaseCategory-module--categoryTitle--9fFpm";
export var categoryDesc = "KnowledgeBaseCategory-module--categoryDesc--bIlq0";
export var categoryContent = "KnowledgeBaseCategory-module--categoryContent--omvNA";
export var categoryContentTitle = "KnowledgeBaseCategory-module--categoryContentTitle--KmbbS";
export var categoryContentList = "KnowledgeBaseCategory-module--categoryContentList--j8oCK";
export var categoryContentListMisc = "KnowledgeBaseCategory-module--categoryContentListMisc--HeSay";
export var categoryItem = "KnowledgeBaseCategory-module--categoryItem--y4sGg";
export var sectionTitle = "KnowledgeBaseCategory-module--sectionTitle--HNqTl";
export var sectionHeader = "KnowledgeBaseCategory-module--sectionHeader--rbY8-";
export var header = "KnowledgeBaseCategory-module--header--MPDPA";
export var top = "KnowledgeBaseCategory-module--top--laumn";
export var search = "KnowledgeBaseCategory-module--search--mrNJN";
export var langSwitch = "KnowledgeBaseCategory-module--langSwitch--OsaFt";