import React, { Component } from 'react';
import cx from 'classnames';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _orderBy from 'lodash/orderBy';
import _filter from 'lodash/filter';
import Layout from 'components/layout';
import Link from 'components/Link';
import Bottom from 'components/Bottom/Bottom';
import SearchResults from 'components/knowledge-base/SearchResults';
import Breadcrumbs from 'components/knowledge-base/Breadcrumbs/Breadcrumbs';
import Translate from 'components/knowledge-base/Translate/Translate';
import getKbContentsDescription from 'utils/kb-category-info';
import { getExcludedLangs } from 'helpers/language';
import {
  KB_OG_IMG,
  KB_OG_IMG_RU,
} from 'constants/index'
import * as s from './KnowledgeBaseCategory.module.css';

class KnowledgeBaseCategory extends Component {
  render() {
    const title = _get(this.props, 'pageContext.title', '');
    const langs = _get(this.props, 'pageContext.langs', []);
    const data = _get(this.props, 'pageContext.data', []);
    const currentLang = _get(this.props, 'pageContext.currentLang', 'en');
    const rootPathes = _get(this.props, 'pageContext.rootPathes', null);

    const grid =
    _orderBy(
      _filter(data, item => {
        return item.children;
      }),
      [
        'category_order',
        ({ title }) => `${title}`.toLowerCase(),
      ],
      ['asc'],
    );

    const misc =
      _orderBy(
        _filter(data, item => {
          return !item.children;
        }),
        ({ title }) => `${title}`.toLowerCase()
      );

    const {
      name,
      description,
      ico,
      icoWidth,
    } = getKbContentsDescription(title, currentLang);

    const { parent_category } = data;

    const rootPath = _get(rootPathes, currentLang, '/');
    const breadcrumbs = [{
      title: <Translate id="Knowledge Base" lang={currentLang} />,
      path: rootPath,
    }];

    if (parent_category) {
      breadcrumbs.push({
        title: <Translate id={data.parent_category} lang={currentLang} />,
      });
    }

    const img = currentLang === 'ru' ? KB_OG_IMG_RU : KB_OG_IMG;

    const meta = {
      title: name,
      meta: [
        { name: 'description', content: description },
        { name: 'keywords', content: description },
        { property: 'og:title', content: name },
        { property: 'og:image', content: img },
        { property: 'og:description', content: description },
        { property: 'twitter:image', content: img },
      ]
    };

    return (
      <Layout
        customMeta={meta}
        pageName={_get(this.props, 'location.pathname')}
        lang={currentLang}
        langs={langs}
        excludedLangs={getExcludedLangs(currentLang)}
        registrationURL={_get(this.props, 'pageContext.registrationURL', '')}
      >
        <div className={s.root}>
          <div className={s.breadcrumbs}>
            <Breadcrumbs data={breadcrumbs} />
          </div>
          <SearchResults>
            <div className={s.wrap}>
              <div className={s.ico}>
                <img src={ico} width={parseInt(icoWidth)} alt="ico" />
              </div>
              <div className={s.categoryWrap}>
                <h1 className={s.categoryTitle}>
                  {name}
                </h1>
                <div className={s.categoryDesc}>
                  {description}
                </div>
                <div>
                  {
                    _map(grid, ({ path, title, children }) => {
                      return (
                        <div className={s.categoryContent} key={path}>
                          <h3 className={s.categoryContentTitle}>{title}</h3>
                          <ul className={s.categoryContentList}>
                            {
                              _map(
                                _orderBy(children, ['order', ({ title }) => `${title}`.toLowerCase()]),
                                ({ path, title, children, section }) => {
                                  if (children && section) {
                                    return (
                                      <>
                                        <li key={path} className={cx(s.categoryItem, s.sectionTitle)}>
                                          <h4 className={s.sectionHeader}>{section}</h4>
                                        </li>
                                        {_map(
                                          _orderBy(children, ['order', ({ title }) => `${title}`.toLowerCase()]),
                                          ({ path, title, children, section }) => (
                                            <li key={path}>
                                              <Link
                                                key={path}
                                                to={path}
                                                className={s.categoryItem}
                                              >
                                                {title}
                                              </Link>
                                            </li>
                                          )
                                        )}
                                      </>
                                    )
                                  }
                                  return (
                                    <li key={path}>
                                      <Link
                                        key={path}
                                        to={path}
                                        className={s.categoryItem}
                                      >
                                        {title}
                                      </Link>
                                    </li>
                                  )
                                }
                              )
                            }
                          </ul>
                        </div>
                      );
                    })
                  }
                </div>
                <div>
                  <ul className={s.categoryContentListMisc}>
                    {
                      _map(misc, ({ path, title, children }) => {
                        return (
                          <li key={path}>
                            <Link
                              key={path}
                              to={path}
                              className={s.categoryItem}
                            >
                              {title}
                            </Link>
                          </li>
                        );
                      })
                    }
                  </ul>
                </div>
              </div>
            </div>
          </SearchResults>
        </div>
        <Bottom/>
      </Layout>
    )
  }
}

export default KnowledgeBaseCategory;
