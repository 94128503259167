import React, { Component } from 'react';
import _map from 'lodash/map';
import Link from 'components/Link';
import icon from 'utils/images/book.svg';
import * as s from './breadcrumbs.module.css';

class Breadcrumbs extends Component {
  render() {
    const { data } = this.props;

    return (
      <div className={s.root}>
        {
          _map(data, (item, index) => {
            if (index === 0) {
              return (
                <Link className={s.item} to={item.path} key={item.path}>
                  <img src={icon} width="24" height="24" alt="icon" />
                  <div>{item.title}</div>
                </Link>
              );
            }

            if (item && item.path) {
              return (
                <Link className={s.item} to={item.path} key={item.path}>
                  <div>{item.title}</div>
                </Link>
              );
            }
          })
        }
      </div>
    );
  }
}

export default Breadcrumbs;
